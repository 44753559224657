import * as React from "react"

import Seo from "../../components/seo"

const EquitableAccessPage = () => {
  return (
    <section id="equitable-access">
      <Seo
        title="Equitable Access"
        description="All students deserve educational equity. Our goal is to ensure every student has the support and resources they need to be successful."
      />
      <div className="one half-pic-left">
        <div className="full-screen">
          <div className="left"></div>
          <div className="right">
            <div className="content">
              <h2>Equitable Access</h2>
              <p>
                An equitable education breaks down the specific challenges and
                barriers faced by individual students and provides personalized
                support to help them succeed.
              </p>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="content">
            <h2>Equitable Access</h2>
            <p>
              An equitable education breaks down the specific challenges and
              barriers faced by individual students and provides personalized
              support to help them succeed.
            </p>
          </div>
        </div>
      </div>
      <div className="two smaller-text-center">
        <div className="container">
          <div className="content">
            <p>
              All students should have access to a quality, tailored education,
              regardless of their racial, ethnic, economic or social background.
            </p>
          </div>
        </div>
      </div>
      <div className="three split-half-circle-right">
        <div className="container">
          <div className="content">
            <div className="left">
              <div className="wrap-content">
                <p>
                  Equitable access to academics, technology and social-emotional
                  support helps all students succeed in a positive and safe
                  learning environment.
                </p>
              </div>
            </div>
            <div className="right">
              <div className="circle-pic"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="four split-half-circle-left  ">
        <div className="container">
          <div className="content">
            <div className="left">
              <div className="circle-pic"></div>
            </div>
            <div className="right">
              <div className="wrap-content">
                <p>
                  It is essential to keep at-risk students and Opportunity Youth
                  connected to one-on-one support, counseling, free broadband
                  and food.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EquitableAccessPage
